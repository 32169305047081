.template-management-container {
  padding: 24px 16px;
  height: 100%;
  .template-management-papers {
    display: flex;
    width: 100%;
    gap: 16px;
    height: 100%;
    .template-management-list {
      width: 35%;
      .paper-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 16px;
        .button-container {
          display: flex;
          align-items: center;
          gap: 4px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid #2cda9b;
          border-radius: 4px;
          padding: 0px 8px;
          height: 30px;
          cursor: pointer;
          .button-text {
            font-size: 12px;
            color: #2cda9b;
          }
          &:hover {
            background: #2cda9b;
            svg {
              fill: #fff;
            }
            .button-text {
              color: #fff;
            }
          }
        }
      }
      .template-management-list-content {
        padding: 16px;
        max-height: 79vh;
        height: 100%;
        overflow: auto;
        .template-item-container {
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1216);
          position: relative;
          cursor: pointer;
          .template-item-name {
            color: #000;
            font-size: 14px;
            width: 100%;
            padding: 4px 0;
          }
          .template-item-dots-container {
            text-align: center;
            padding: 4px 0;
            width: 30px;
            &:hover {
              background: #d3e5fa;
            }
          }
          .template-menu {
            display: flex;
            flex-direction: column;
            background: #fff;
            padding: 8px 0px;
            position: absolute;
            right: 0;
            top: 100%;
            border-radius: 4px;
            box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
            z-index: 500;
            min-width: 125px;
            .template-menu-item {
              font-size: 12px;
              font-weight: 500;
              padding: 8px;
              &:hover {
                background: #e7f0fa;
              }
            }
          }
          &:hover {
            background: #e7f0fa !important;
          }
          &.disabled {
            background: #f0f0f0;
            .template-item-name {
              color: #b5b5b5;
              font-weight: normal;
            }
          }
          &.selected {
            background: #e7f0fa;
          }
        }
      }
    }
    .template-management-edit-paper {
      width: 65%;
      .template-management-edit-content {
        max-height: 79vh;
        overflow: auto;
        .template-management-edit-form {
          display: flex;
          flex-direction: column;
          gap: 16px;
          padding: 16px;
          .template-management-input-container {
            max-width: 100%;
            min-height: 42px;
            .template-management-input-label {
              font-size: 14px;
              color: #000;
              margin-bottom: 4px;
            }
          }
          .template-management-placeholders-container {
            margin-bottom: 16px;
            .template-management-placeholders-description {
              display: flex;
              align-items: center;
              font-size: 14px;
              margin-bottom: 4px;
              color: #000;
              gap: 6px;
              div {
                padding-bottom: 2px;
              }
            }
            .template-management-placeholders {
              display: flex;
              flex-wrap: wrap;
              gap: 8px;
              .template-management-placeholder {
                padding: 2px 8px;
                border: 1px solid rgba(0, 0, 0, 0.1216);
                border-radius: 4px;
                color: #333;
              }
            }
          }
        }
        .actions-container {
          display: flex;
          justify-content: end;
          gap: 16px;
          border-top: 1px solid #eaeaf0;
          padding: 20px;
          position: sticky;
          bottom: 0;
          left: 0;
          background: #fff;
          border-radius: 0 0 4px 4px;
        }
      }
    }
  }
}

.confirmation-modal {
  .modal-body {
    padding: 32px 16px;
  }
  .modal-footer {
    width: 100%;
    margin: 0;
    .footer-container {
      width: 100%;
      display: flex;
      gap: 16px;
      justify-content: end;
      button {
        width: 100%;
      }
    }
  }
}

.test-email-modal {
  .test-email-modal-body {
    padding: 16px;
    .email-input-container {
      align-items: center;
      gap: 16px;
      label {
        font-size: 14px;
        margin-bottom: 4px;
      }
      input {
        margin-bottom: 4px;
      }
    }
    .input-error {
      border-color: red !important;
      &:focus,
      &:focus-visible {
        border-color: red !important;
        box-shadow: unset !important;
        outline: none;
      }
    }
  }
}
