.reports-container {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  gap: 8px;
  padding: 16px;
  height: 100%;
  background: white;
  margin: 0 16px;
  max-height: calc(100vh - 59px);
  .reports-title {
    font-weight: bold;
  }
  .reports-content {
    overflow: hidden;
    height: 100%;
    .reports-dashboard,
    .reports-data-extraction {
      height: 100%;
    }
  }
}
