.dashboard-container {
  height: 100%;
  .dashboard-content {
    display: flex;
    gap: 32px;
    padding: 16px;
    height: 100%;
    width: 100%;
    .dashboard-content-left-container {
      height: 100%;
      overflow-y: auto;
      width: 70%;
      padding-right: 16px;
      .dashboard-content-left {
        display: flex;
        flex-direction: column;
        gap: 32px;
        height: 100%;
        .dashboard-cards {
          display: flex;
          gap: 16px;
          width: 100%;
          .dashboard-card {
            display: flex;
            flex-direction: column;
            gap: 4px;
            border: 1px solid #0000001f;
            border-radius: 8px;
            padding: 12px;
            width: 170px;
            position: relative;
            .dashboard-card-description {
              display: flex;
              flex-direction: column;
              gap: 2px;
              .dashboard-card-title {
                font-weight: bold;
                font-size: 14px;
              }
              .dashboard-card-subtitle {
                font-size: 12px;
                color: #3e3e3e;
              }
            }
            .dashboard-card-info {
              position: absolute;
              top: 4px;
              right: 8px;
            }
            .dashboard-card-data {
              font-size: 40px;
              color: black;
            }
          }
        }
        .dashboard-charts {
          width: 95%;
        }
      }
    }
    .dashboard-content-right {
      display: flex;
      width: 30%;
      border: 1px solid #0000001f;
      border-radius: 4px;
      flex-direction: column;
      height: 100%;
      .dashboard-content-right-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #0000001f;
        padding: 4px 16px;
        .dashboard-form-title {
          color: #5e677b;
          font-weight: bold;
          font-size: 12px;
        }
      }
      .dashboard-content-right-body {
        display: flex;
        gap: 16px;
        padding: 16px;
        flex-direction: column;
        overflow-y: auto;
        padding-bottom: 24px;
        .dashboard-date-inputs {
          display: flex;
          gap: 16px;
          .dashboard-input-container {
            width: 100%;
            min-height: 42px;
            .dashboard-input-label {
              font-size: 14px;
              color: #000;
              margin-bottom: 4px;
            }
          }
        }
      }
      .dashboard-buttons {
        display: flex;
        gap: 16px;
        justify-content: end;
        align-items: center;
        width: 100%;
        padding: 16px;
        background: white;
        border-top: 1px solid #0000001f;
      }
    }
  }
}
