.rule-violation-card-container {
  position: relative;
  display: flex;
  align-items: start;
  justify-content: left;
  gap: 12px;
  height: fit-content;
  padding: 6px 0 6px 15px;
  transition: all 0.2s;
  cursor: pointer;
  font-size: 12px;
  width: 100%;
  &:hover,
  &.selected {
    background-color: #e7f0fa;

    p.time {
      color: #3478f6;
      background-color: unset;
    }
  }
  p {
    margin: 0;
  }
  .rule-violation-card-time-line {
    .time-line {
      position: absolute;
      background-color: #e7f0fa;
      width: 3px;
      height: 100%;
      left: 34.5px;
      top: -12px;
    }
    p.time {
      top: 4px;
      position: relative;
      z-index: 2;
      min-width: 42px;
      color: #999999;
      transition: all 0.2s;
    }
  }
  .rule-violation-container {
    max-width: 80%;
    width: 100%;
    margin: 0;
    padding-right: 8px;
    @media (min-width: 1025px) {
      max-width: 85%;
    }
    @media (min-width: 1400px) {
      max-width: 90%;
    }
    .rule-violation-div {
      width: 100%;
      position: relative;
      display: flex;
      align-items: start;
      justify-content: center;
      flex-direction: column;
      gap: 8px;
      p.issueExplanation {
        color: #444;
      }
      div.buttons {
        display: flex;
        gap: 10px;
        position: absolute;
        z-index: 3;
        margin: 0;
        right: 16px;
        top: 4px;
        svg {
          width: 24px;
          height: 24px;
          color: rgba(161, 172, 178, 1);
          &.edit {
            color: rgba(194, 203, 210, 1);
          }
        }
        button {
          margin: 0;
          padding: 0;
          background: none;
          border: none;
        }
      }
    }
    .issueCardContainer {
      display: flex;
      width: 100%;
      justify-content: space-between;
      .card-tag-container {
        display: flex;
        align-items: center;
        width: 70%;
        gap: 6px;
        .rule-violation-card {
          display: flex;
          align-items: center;
          gap: 4px;
          background: rgba(236, 126, 25, 0.2);
          color: red;
          width: fit-content;
          padding: 3px 6px;
          border-radius: 5px;
          max-height: 24px;
          overflow: hidden;
          svg {
            min-width: 18px;
          }
          p {
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .violation-tags-container {
          display: flex;
          gap: 6px;
        }
      }
      .issueExplanation {
        margin: 0 10px;
        width: 100%;
      }
      .actions-card {
        display: flex;
        gap: 10px;
        font-size: 16px;
      }
    }
  }
}
