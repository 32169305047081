.submission-title-container {
  display: flex;
  width: 100%;
  max-width: 100vw;
  margin: 0% 1% 0%;
  align-items: center;
  gap: 16px;
  overflow-x: hidden;
  .submission-title {
    font-weight: bold;
    max-width: 50%;
  }
  .submission-version-container {
    display: flex;
    align-items: center;
    .submission-select-version-label {
      font-weight: bold !important;
      color: #aaaaaa !important;
      font-size: small !important;
    }
    Select {
      background-color: transparent !important;
      color: rgba(0, 0, 0, 0.6) !important;
      font-size: small !important;
    }
  }
}

.submission-header-container {
  display: flex;
  margin: 15px 0px;
  .line-after-tabs {
    width: unset !important;
  }
  .tabs-container {
    width: unset !important;
    .tab-button {
      width: max-content !important;
    }
  }
  .submission-header-content {
    display: flex;
    width: 100%;
    justify-content: space-between;
    border-bottom: 1px solid #dcdcdc !important;
    padding: 0px 10px;
    padding-bottom: 4px;
    font-weight: bold;
    align-items: center;
    .decision {
      .decision-details {
        display: flex;
        align-items: center;
        font-size: small;
        .decision-title {
          color: #aaaaaa;
          padding: 0lvh 1lvh 0lvh 2lvh;
        }
        .decision-value {
          display: flex;
          align-items: center;
        }
      }
    }
    .right-side-items {
      position: relative;
      display: flex;
      gap: 16px;
      .right-side-items-button {
        display: flex;
        align-items: center;
        background-color: transparent;
        border: 1px solid #2cda9b;
        color: #2cda9b;
        transition: 0.5s;
        max-height: 36px;
        padding: 6px;
        &.right-side-items-button-active {
          background-color: #2cda9b;
          color: #fff;
          transition: 0.5s;
        }
        &:focus {
          box-shadow: none;
        }
      }
    }
  }
}
.submission-content {
  width: 100%;
}

.transcriptParagraph .issue-text-match {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }

  &.green {
    color: green;
  }
  &.purple {
    color: rgba(113, 95, 204, 1);
    font-weight: 700;
  }
  &.orange {
    color: rgba(236, 126, 25, 1);
    font-weight: 700;
  }
  &.red {
    color: red;

    * {
      color: red !important;
    }
  }
  &.black {
    color: #00a;
  }
  &.selected {
    background-color: lightgray;
  }
  &.bold {
    font-weight: 700;
  }
}

.contentCards {
  display: flex;
  gap: 2%;
  width: 100%;
}

.reviewTab.contentCards {
  display: block;
}

.contentCard {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.paper-title {
  height: 40px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.paperText {
  color: #5e677b;
  font-weight: bold;
  padding: 0px 15px 0px;

  font-size: 12px;
}

.mainResults {
  max-height: 60%;
}

.resultDiv {
  height: 77%;
}

.paperContent {
  height: 77%;
}
.marginLeftAuto {
  margin-right: auto;
}
.cursor {
  cursor: pointer;
}

.detailsHeader {
  display: flex;
  flex-flow: row;
  gap: 0px;
  margin-bottom: 15px;
  flex-wrap: wrap;
  padding: 10px;
  span {
    padding: 5px;
    width: 45%;
  }
  span.last {
    width: 100%;
  }
}

.modal-title {
  font-weight: bold;
}

.modal-body {
  padding: 0px 26px 10px;
}

.modalButton {
  padding: 5px 20px 5px !important;
}

.modal-header {
  border-bottom: 1px solid #dee2e6;
  padding: 10px 15px;
}

.modal-footer {
  border-top: white;
  margin: 0px 10px 10px;
  padding: 10px;
  align-self: flex-end;
}

.content-transcript-tabs {
  min-height: auto !important;

  .tab {
    width: 50%;
    padding: 0px 12px 8px;
  }
}

.dataParagraph {
  text-align: justify;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  padding: 10px;
  border: 0;
}

.loading-screen-submission {
  width: 100%;
  height: calc(100vh - 60px);

  display: flex;
  align-items: center;
  justify-content: center;
}

.functionals {
  display: flex;
  align-items: center;
  height: 40px;

  button {
    background: none;
    border: none;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    padding: 8px;
    height: 39px;

    &:focus {
      box-shadow: none !important;
      background-color: none;
    }

    svg {
      width: 14px;
      height: 14px;
      stroke-width: 0.1px;

      &.textTop,
      &.texBottom {
        width: 20px;
        height: 20px;
      }

      &.textTop {
        margin-top: 5px;
      }
      &.texBottom {
        margin-top: -8px;
      }
      &.expand {
        margin-top: -2px;
      }
    }
  }

  .optButtons {
    display: flex;
  }
}
