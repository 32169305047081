.tabs-container {
  display: flex;
  width: 100%;
  .line-before-tabs {
    width: 20px;
    border-bottom: 1px solid #dcdcdc !important;
  }
  .line-after-tabs {
    width: 100%;
    border-bottom: 1px solid #dcdcdc !important;
  }
  .tab-button {
    font-weight: bold;
    font-size: small;
    color: #5e677b !important;
    padding: 0px 20px !important;
    border-radius: 8px 8px 0 0 !important;
    width: 100%;
    max-width: 135px;
    border: 1px solid #dcdcdc !important;
    background-color: #e9ebee !important;
    min-height: 35px;
    &.active {
      background-color: transparent !important;
      border-bottom: 0px solid transparent !important;
      &:hover {
        border-bottom: 0px solid transparent !important;
      }
      &:focus {
        border-bottom: 0px solid transparent !important;
      }
    }
    &:focus {
      background-color: transparent !important;
      box-shadow: none !important;
      border: 1px solid #dcdcdc !important;
    }
  }
}
