:root {
  --primary: #2b6747;
  --secondary: #c9dfd3;
  --tertiary: #f0f8f4;

  --text-black: #424242;
  --text-gray: #858e93;

  --detail-gray: #a1acb2;
  --detail-border: #cdcdcd;

  --warning-error: #ff4949;
  --warning-success: #4aa04a;
  --warning-orange: #fa9f47;
  --warning-blue: #4994ee;
  --warning-disable: #dddddd;

  --bg-white: #ffffff;
  --bg-gray: #f5f5f5;
  --bg-yellow: #fff3d6;
  --bg-red: #fff0f0;
  --bg-green: #cdfcbd;
  --bg-blue: #e4effd;

  --box-shadow-small: 0px 2px 4px #b3b3b3;
}

body {
  margin: 0;
  background-color: #f6f5fb;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a:hover {
  color: #2cda9b;
}

.container {
  background-color: transparent;
  max-width: none !important;
}

.bg-primary {
  background-color: #2cda9b !important;
}

.form-control::placeholder {
  color: #c2cace !important;
}

.badge.bg-primary,
.dropdown-item:active {
  background-color: #2cda9b !important;
}

.badge {
  border-radius: 72px;
}

.dropdown-item:hover,
.dropdown-item:focus {
  background-color: 2CDA9B (94, 103, 123, 0.25);
}

.form-label {
  color: #2cda9b;
}

.levelHigh {
  margin-left: 3px;
  color: #2cda9b;
}

.levelMedium {
  margin-left: 3px;
  color: #fccf1a;
}

.levelLow {
  margin-left: 3px;
  color: #ff5216;
}

.decisionValue.accept {
  color: #2cda9b;
}

.decisionValue.reject {
  color: #ff5216;
}

.react-tooltip {
  z-index: 9999;
}

/* Common button styles */
.cc-button {
  max-width: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 35px;
  border-radius: 4px;
  padding: 0px 8px;
  transition: 0.5s;
  &:hover {
    transition: 0.5s;
  }
  &:disabled {
    pointer-events: none;
    opacity: 0.65;
  }
}

/* Specific styles for each button type */
/* Primary button */
.cc-primary-button {
  background-color: #2cda9b;
  color: #fff;
  border: none;
  &:hover {
    background-color: #25bb84 !important;
    color: #fff;
  }
}
/* Primary button (danger variant) */
.cc-primary-button-danger {
  background-color: #dc3545;
  color: #fff;
  border: none;
  &:hover {
    background-color: #be2e3c !important;
    color: #fff;
  }
}

/* Secondary button */
.cc-secondary-button {
  background-color: #5282cf;
  color: #fff;
  border: none;
  &:hover {
    background-color: #3068c4 !important;
    color: #fff;
  }
}

/* Outlined button */
.cc-outlined-button {
  background-color: transparent;
  color: #5e677b;
  border: 1px solid #5e677b;
  &:hover {
    border: 1px solid #2cda9b;
    color: #2cda9b;
    background: unset;
    box-shadow: none;
  }
  &:disabled {
    color: #96a0b6;
    border: 1px solid #96a0b6;
  }
}
/* Outlined button (danger variant) */
.cc-outlined-button-danger {
  background-color: transparent;
  color: #5e677b;
  border: 1px solid #5e677b;
  &:hover {
    border: 1px solid #dc3545;
    color: #dc3545;
    background: unset;
    box-shadow: none;
  }
  &:disabled {
    color: #96a0b6;
    border: 1px solid #96a0b6;
  }
}

.cc-input {
  width: 100%;
  height: 0;
  min-height: 25px;
  border: 1px solid #aaa;
  border-radius: 4px;
  padding: 8px;
  font-size: 14px;
  &:focus {
    outline: none;
  }
}
.cc-paper-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 16px;
  height: 40px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  .button-container {
    display: flex;
    align-items: center;
    gap: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #2cda9b;
    border-radius: 4px;
    padding: 0px 8px;
    height: 30px;
    cursor: pointer;
    .button-text {
      font-size: 12px;
      color: #2cda9b;
    }
    &:hover {
      background: #2cda9b;
      svg {
        fill: #fff;
      }
      .button-text {
        color: #fff;
      }
    }
  }
}
